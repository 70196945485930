<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <form @submit.prevent="updateReport()">
          <div id="pagelayout">
            <div class="columns">
              <div class="column">
                <!-- REPORT PROPERTIES -->
                <div
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Report Properties</span>
                </div>
                <div class="block">
                  <div>
                    <div class="field">
                      <label class="label is-size-7">
                        Report Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.displayName" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Report Type
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control select">
                        <select v-model="input.typeCode">
                          <option
                            v-for="opt in reportTypesArr.filter(type => type.category === 'client')"
                            :key="opt.code"
                            :value="opt.code"
                          >{{opt.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Report Upload Date
                        <span class="has-text-warning"
                              style="padding-left: 3px;">*</span>
                      </label>
                      <kendo-datepicker id="uploaddate"
                                        name="uploaddate"
                                        style="width: 200px"
                                        required="required"
                                        v-model="input.uploadDate"
                                        :format="'yyyy-MM-dd'"
                                        v-on:change="onUploadDateChange">
                      </kendo-datepicker>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">Report Year Override</label>
                      <div class="control">
                        <input
                          :class="{'input': true, 'is-danger': inputError && inputError.yearOverride}"
                          type="text"
                          name="typecode"
                          min="4"
                          max="4"
                          v-model="input.yearOverride"
                        />
                      </div>
                    </div>
                    <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem; margin-bottom: 1rem;" v-show="inputError && inputError.yearOverride">
                      <span class="has-text-weight-bold">Error</span>
                      <ul id="example-1">
                        <li style="margin-top: 5px;">{{inputError.yearOverride}}</li>
                      </ul>
                    </div>
                    <div>
                      <file-pond name="file"
                        v-if="$hasPermissions(clientSession, ['CLIENT_REPORTS'], 2)"
                        ref="pond2"
                        label-idle="<div><span class='icon' style='padding-top:20px; font-size: 3rem;'><i class='fas fa-3x fa-cloud-upload-alt'></i></span></div><div>Click here or drop a file here to replace this report</div>"
                        allow-multiple="false"
                        accepted-file-types="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :files="input.files"
                        v-on:addfile="onFileAdd"
                        v-on:removefile="removeFile"
                        :dropOnElement="false"
                        :dropOnPage="true"
                      />
                      <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem" v-show="errors.length>0">
                  <span class="has-text-weight-bold">Error</span>
                  <ul id="example-1">
                    <li style="margin-top: 5px;" v-for="(err,idx) in errors" :key="err + idx">{{ err }}</li>
                  </ul>
                </div>
                      <div id="report-file-info-section" style="padding-top: 40px;">
                        <div id="report-file-info-section-heading"
                             class="block"
                             style="margin-bottom: 10px; padding: 0px 0px px 0px; border-bottom: solid 1px lightgray;">
                          <span class="has-text-weight-bold">Notifications</span>
                        </div>
                        <div id="report-file-info-section-data" class="block">
                          <input type="checkbox" id="sendEmail" name="sendEmail" v-model="sendEmail">
                          <label for="sendEmail"
                                 class="is-size-7"
                                 style="padding: 0px 7px;">Send email notification</label>
                          <div class="field" style="padding-top: 7px; padding-left: 20px;" v-if="sendEmail">
                            <label class="label is-size-7">Email Template</label>
                            <div class="control">
                              <div class="select">
                                <select v-model="emailTemplateId">
                                  <option selected>Select a template to use</option>
                                  <option v-for="template in emailTemplates"
                                          :key="template.id"
                                          :value="template.id">
                                    {{template.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <!-- INSTITUTION INFORMATION -->
                <div id="institution-info-section">
                  <div id="institution-info-section-heading"
                       class="block"
                       style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                    <span class="has-text-weight-bold">Institution Information</span>
                  </div>
                  <div id="institution-info-section-data" class="block">
                    <div class="columns is-vcentered">
                      <div class="column">
                        <div class="field">
                          <label class="label is-size-7">
                            Institution
                            <span class="has-text-warning" style="padding-left: 3px;">*</span>
                          </label>
                          <div class="control">
                            <div class="select">
                              <select v-model="input.institutionId" :disabled="true">
                                <option v-for="opt in institutionsArr"
                                        :key="opt.id"
                                        :value="opt.id">
                                  {{opt.id}} {{opt.name}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="has-text-centered">
                          <label class="label is-size-7">
                            {{ (institutions.get(input.institutionId) || {}) .name}}
                            <br />
                            {{(institutions.get(input.institutionId)|| {}) .city}}, {{(institutions.get(input.institutionId) || {}) .stateCode}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="report-access-list-section" style="padding-top: 40px;">
                  <div id="report-access-list-section-heading"
                       class="block"
                       style="margin-bottom: 10px; padding: 0px 0px px 0px; border-bottom: solid 1px lightgray;">
                    <span class="has-text-weight-bold">Report Access List</span>
                  </div>
                  <div id="report-access-list-section-data" class="block">
                    <div>
                      <table class="table is-size-7">
                        <tr>
                          <th class="is-narrow" style="border-bottom: none;">Institution</th>
                          <th style="border-bottom: none;">Name</th>
                          <th style="border-bottom: none;">Email Address</th>
                          <th style="border-bottom: none;">Role</th>
                        </tr>
                        <tr v-for="mem in rMembs" :key="mem.userId">
                          <td>{{ mem.institutionIds && mem.institutionIds.length === 1 ? mem.institutionIds[0] : '-' }}</td>
                          <td>{{ usersMap.get(mem.userId).firstName + " " + usersMap.get(mem.userId).lastName }}</td>
                          <td>{{ usersMap.get(mem.userId).email }}</td>
                          <td>{{ usersMap.get(mem.userId).role }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div id="report-file-info-section" style="padding-top: 40px;">
                  <div id="report-file-info-section-heading"
                       class="block"
                       style="margin-bottom: 10px; padding: 0px 0px px 0px; border-bottom: solid 1px lightgray;">
                    <span class="has-text-weight-bold">Report File Information</span>
                  </div>
                  <div id="report-file-info-section-data" class="block">
                    <div>
                      <table class="table is-size-7">
                        <tr>
                          <td class="is-narrow" style="border-bottom: none;">File Name:</td>
                          <td style="border-bottom: none;">{{ input.displayName }}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">File Type:</td>
                          <td style="border-bottom: none;">{{getFileType(input.extension)}}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">File Size:</td>
                          <td style="border-bottom: none;">{{ input.fileSize | prettyBytes }}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">Download:</td>
                          <td style="border-bottom: none;">
                            <a :href="`${input.path}`">
                              <i class="fas fa-download fa-2x"></i>
                            </a>
                            &nbsp;
                            <a href="#" @click.prevent="openReport()"
                               :target="'_blank'">
                              <i class="fas fa-eye fa-2x"></i>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div id="buttongroup" style="padding-top: 20px;">
            <div class="field is-grouped">
              <div class="control">
                <button type="submit" class="button is-accent has-text-white" :disabled="isPageDisabled === true || isSaving || !$hasPermissions(clientSession, ['CLIENT_REPORTS'], 2)">
                  {{ isSaving ? "Saving" : "Save" }}

                  <spin-loader v-if="isSaving" style="margin-left: .75rem;" />
                </button>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import moment from 'moment'
// import { Toast } from 'buefy/dist/components/toast'
// import axios from 'axios'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

let pageName = 'Report Details'
const setCompOptions = (that, fileID) => {
  // alert(fileID)
  setOptions({
    allowRevert: false,
    allowProcess: false,
    instantUpload: false,
    server: {
      url: `${process.env.VUE_APP_API_URL}`,
      process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        let payload = new FormData()
        payload.append('file', file)

        that.input.uploadDate = new Date(that.input.uploadDate).toISOString().slice(0, 19).replace('T', ' ')
        payload.append('report.UploadDate', new Date().toUTCString())

        payload.append('report.DisplayName', that.input.displayName)
        payload.append('report.Name', that.input.name)
        payload.append('report.InstitutionId', that.input.institutionId)
        payload.append('report.TypeCode', that.input.typeCode)
        payload.append('report.Extension', that.input.extension)
        payload.append('report.YearOverride', that.input.yearOverride)

        let response = await that.$http.put(
          `reports/${that.$route.params.id}`,
          payload,
          {
            onUploadProgress (progressEvent) {
              progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
            }
          }
        )

        if (response.status >= 200 && response.status < 300) {
          // the load method accepts either a string (id) or an object
          load(response.data)
          that.finally()
          that.input = response.data
        } else {
          // Can call the error method if something is wrong, should exit after
          error('oh no')
        }

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            response.abort()

            // Let FilePond know the request has been cancelled
            abort()
          }
        }
      },
      revert: null,
      fetch: null,
      restore: null,
      load: null
    }
  })
}

export default {
  components: {
    FilePond,
    Loading,
    'kendo-datepicker': DatePicker
  },
  watch: {
    rInstitutionId () {
      this.updateAcl()
    },
    rTypeCode () {
      this.updateAcl()
    }
  },
  props: {
    model: {
      default: () => {
        return {
          id: '',
          displayName: '',
          name: '',
          typeCode: '',
          typeCodeDescription: '',
          institutionId: '',
          institutionName: '',
          yearOverride: '',
          extension: '',
          uploadDate: '',
          fileSize: 0,
          sendEmail: false,
          emailTemplateId: 0
        }
      }
    }
  },
  data () {
    return {
      input: {
        id: '',
        name: '',
        displayName: '',
        typeCode: '',
        typeCodeDescription: '',
        institutionId: '',
        institutionName: '',
        yearOverride: '',
        extension: '',
        uploadDate: '',
        fileSize: 0,
        files: [],
        sendEmail: false,
        emailTemplateId: 0
      },
      isSaving: false,
      hasConfirmedSave: false,
      isLoading: false,
      reportTypes: new Map(),
      reportTypesArr: [],
      institutions: new Map(),
      institutionsArr: [],
      files: [],
      memberships: [],
      usersArr: [],
      pageheading: pageName,
      emailTemplates: [],
      isPageDisabled: false,
      sendEmail: false,
      emailTemplateId: null,
      inputHash: null,
      errors: [],
      inputError: {
        yearOverride: ''
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      return !id || id <= 0
    },
    rInstitutionId () {
      return this.input.institutionId
    },
    rTypeCode () {
      return this.input.typeCode
    },
    usersMap () {
      return new Map(this.usersArr.map(i => [i.id, i]))
    },
    rMembs () {
      let u = this.usersMap
      let result = this.memberships.filter(x => u.get(x.userId) !== undefined)
      return result
    }
  },
  async created () {
    await this.getReportTypes()
    await this.getInstitutionsLookup()
    await this.getEmailTemplates()
    await this.loadFormData()
    // TODO: if external direct link, need to load the model state

    window.addEventListener('beforeunload', this.shouldLeave)
  },
  async beforeRouteEnter (to, from, next) {
    if (to.params.skip) { localStorage.setItem('reportSkip', to.params.skip) }

    let report = null

    try {
      report = JSON.parse(to.params.model)
    } catch (_error) {
      report = to.params.model
    }
    if (report) {
      next(vm => {
        vm.input.id = report.bio
        vm.input.displayName = report.displayName
        vm.input.name = report.name
        vm.input.typeCode = report.typeCode
        vm.input.typeCodeDescription = report.typeCodeDescription
        vm.input.institutionId = report.institutionId
        vm.input.institutionName = report.institutionName
        vm.input.yearOverride = report.yearOverride
        vm.input.extension = report.extension
        vm.input.uploadDate = vm.getUploadDate(report.uploadDate).format()
        vm.input.fileSize = report.fileSize
        vm.input.path = report.path
      })
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.beforeWindowUnload(null, next)
  },
  methods: {
    async loadFormData () {
      if (!this.isCreateMode && (!this.model || this.model.id <= 0 || this.model.id === undefined)) {
        await this.loadModel(`/reports`)
      } else {
        await this.setupForm(this.model)
      }
      this.input.uploadDate = this.getUploadDate(this.input.uploadDate).format()
      this.inputDefault = this.input

      this.inputHash = this.clone(this.input, true)
      setCompOptions(this, this.input.id)
    },
    getFileType (extension) {
      switch (extension) {
        case '.pdf':
          return 'Adobe Acrobat PDF'
        case '.xls':
          return 'Microsoft Excel Spreadsheet'
        case '.xlsx':
          return 'Microsoft Excel Spreadsheet'
        default:
          return extension.toUpperCase() + ' File'
      }
    },
    hasChanged () {
      let hasChanged = false
      Object.keys(this.input).forEach(_key => {
        if (this.input[_key] !== this.inputHash[_key] && _key !== 'files') {
          hasChanged = true
        }
      })

      return hasChanged
    },
    confirmLeave () {
      return confirm('Do you really want to leave? You have unsaved changes!')
    },
    shouldLeave (e) {
      let shouldLeave = true

      if ((this.input.files.length > 0 || this.hasChanged())) {
        if (!this.confirmLeave()) {
          if (e !== null && e !== undefined) {
            // Cancel the event
            e.preventDefault()
            // Chrome requires returnValue to be set
            e.returnValue = ''
          }

          shouldLeave = false
        }
      }

      return shouldLeave
    },
    beforeWindowUnload (e, next) {
      let shouldLeave = (this.input.files !== undefined && (this.input.files.length > 0 || this.hasChanged())) && !this.shouldLeave()
      if (shouldLeave) {
        next(false)
      } else {
        next()
      }
    },
    async onFileAdd (arg, file) {
      try {
        let reportMeta = this.parseReportName(file.filename)
        this.errors = []
        let err = ''
        if (!this.institutions.get(reportMeta.institutionId)) { err += `${file.filename} references and invalid institution id ${reportMeta.institutionId} and has been removed.` }

        if (!this.reportTypes.get(reportMeta.typeCode)) { err += `${file.filename} references and invalid report type code ${reportMeta.typeCode} and has been removed.` }

        if (this.reportTypesArr.filter(type => type.category === 'client' && type.code === reportMeta.typeCode).length === 0) {
          err += `${file.filename}: references an invalid report type code "${reportMeta.typeCode}" for client reports. This type of document doesn't belong here.`
        }

        if (err.length > 0) {
          await file.abortLoad()
          this.errors.push(err)
          return
        }

        this.input.uploadDate = new Date().toISOString().slice(0, 10)
        this.input.fileSize = file.fileSize
        this.input.extension = reportMeta.extension
        this.input.yearOverride = new Date(
          reportMeta.yearOverride.toString()
        ).getUTCFullYear()
        this.input.name = reportMeta.name
        this.input.typeCode = reportMeta.typeCode

        this.files.push(file)
      } catch (error) {
        await file.abortLoad()
        this.errors.push(error.message)
      }
    },
    async updateAcl () {
      try {
        let id = this.input.institutionId
        let code = this.input.typeCode
        let route = `/memberships/search?reportTypeCode=${code}&institutionId=${id}`

        // TODO: seperate sanity check on institution id
        let rt = this.reportTypes[code]
        if (
          this.input.institutionId <= 0 ||
          rt === null ||
          rt === undefined ||
          !rt.id ||
          rt.id <= 0
        ) {
          this.memberships = []
        }

        let response = await this.axios.get(route)
        let memberships = response.data.records
        await this.updateUsers(
          memberships.map(m => {
            return m.userId
          })
        )
        this.memberships = memberships
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    async updateUsers (membershipIds) {
      try {
        let route = `/users/search/`

        let payload = {
          filter: { id: { $in: membershipIds } },
          limit: 10000
        }

        let response = await this.axios.post(route, payload)
        this.usersArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    async getInstitutionsLookup () {
      // TODO: dedupe
      try {
        let route = `/institutions/`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.institutions = new Map(response.data.records.map(i => [i.id, i]))
        this.institutionsArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getReportTypes () {
      try {
        this.isLoading++
        let response = await this.$http.get('/report-types')
        this.reportTypes = new Map(response.data.records.map(i => [i.code, i]))
        this.reportTypesArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading--
      }
    },
    async removeFile () {
      this.files = []
      await this.loadFormData()
    },
    openReport () {
      this.$router.push({ name: 'ViewReport', params: { id: this.input.id, 'report': this.input } })
    },
    async finally () {
      let that = this

      if (that.sendEmail) {
        await that.publish()
      }

      await this.loadFormData()

      setTimeout(() => {
        that.isSaving = false
        that.$router.go(-1)

        that.successToast('Saved!')
      }, 1500)
    },
    async updateReport () {
      try {
        let files = this.$refs.pond2.getFiles()
        if (files.length === 0) {
          if (this.input.yearOverride.toString().length !== 4) {
            this.inputError.yearOverride = 'Year Override must have 4 digits.'
            return
          } else {
            this.inputError.yearOverride = null
          }

          this.isSaving = true
          setCompOptions(this, this.input.id)
          let payload = new FormData()

          payload.append('report.DisplayName', this.input.displayName)
          payload.append('report.Name', this.input.name)
          payload.append('report.InstitutionId', this.input.institutionId)
          payload.append('report.TypeCode', this.input.typeCode)
          payload.append('report.Extension', this.input.extension)
          payload.append('report.YearOverride', this.input.yearOverride)

          this.input.uploadDate = new Date(this.input.uploadDate).toISOString().slice(0, 19).replace('T', ' ')
          payload.append('report.UploadDate', this.input.uploadDate)

          let response = await this.$http.put(
            `reports/${this.$route.params.id}`,
            payload
          )

          this.input = response.data

          this.finally()
        } else {
          this.isSaving = true
          setCompOptions(this, this.input.id)
          this.$refs.pond2.processFiles().then(this.finally)
        }
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    async publish () {
      try {
        // TODO: email template id
        this.isLoading = true
        let route = `/reports/publish`
        let params = {
          params: { ids: [this.input.id], emailTemplateId: this.sendEmail ? this.emailTemplateId : null }
        }
        await this.axios.post(route, null, params)
        // this.successToast('Published Reports', { name: 'Reports' })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getEmailTemplates () {
    // TODO dedupe
      try {
        this.isLoading = true
        let response = {}
        let route = `/email-templates/search`
        let payload = { filter: { type: 'Reporting' } }
        response = await this.axios.post(route, payload)
        const templates = response.data.records
        if (templates) {
          this.emailTemplates = response.data.records
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    onUploadDateChange: function (e) {
      this.input.uploadDate = moment(e.sender.value()).utc().toLocaleString()
    }
  }
}
</script>

<style>
  input[type="checkbox"]:disabled,
  input[type="checkbox"]:disabled+label{
    cursor:no-drop;
  }
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.filepond--list-scroller {
  transform: translate3d(0px, 0px, 0) !Important;
}
.filepond--drop-label {
  font-size: 1.5rem;
  color: #7fb942;
}
</style>
